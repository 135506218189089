
.content {
  width: 100%;
 display: flex;
 justify-content: center;
 align-items: center;
 flex-direction: column;
}
.carouselslide{
margin-top: 50px;
margin-bottom: 50px;
}

.carousel {
  width: 100%;
  /* Add more styles as needed */
}
.content{
 
background-color: #f8f9faaf;
/* width: 100%; */
/* max-width: 120%; */
overflow-x: hidden;
}
.carousel-item {

    height: 500px; /* Set your desired height here */
    /* width: 100%; */
    margin-top: 30px;
    margin-left: 20px;
  }

.card-group {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  margin: 0 auto; /* Center horizontally */
  max-width: 1200px; /* Optionally limit the width */
 margin-top: 50px;
 margin-bottom: 80px;
}

.card {
  width: 300px;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease;
}

.card:hover {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.card-body {
  padding: 20px;
}

.card-title {
  font-size: 20px;
  margin-bottom: 10px;
}

.card-text {
  font-size: 16px;
  text-align: justify;
  color: #555;
}
/* Media query for screen sizes between 600px and 780px */
@media only screen and (min-width: 600px) and (max-width: 780px) {
 .carouselimg{
  width:750px ;
 }
}

@media only screen and (min-width: 400px) and (max-width: 600px) {
  .carouselimg{
   width:500px ;
   height: 300px;
  }
 }
 