* {
  box-sizing: border-box;
}
body {
  margin: 0;
  font-family: 'Segoe UI';
}
.nav {
  width: 100%;
  background-color:#28a745;
  display: flex;
  align-items: center;
  height: 100px;
}
.nav > .nav-header {
  flex: 0.95;
  padding-left: 1rem;
}
.nav-title img{
 margin-top: 5px;
margin-bottom: 5px;
 height: 70px;
background-color: white;
border-radius: 5px;
}
.nav > .nav-list {
  display: flex;
  gap: 2rem;
}
.nav > .nav-list > li {
  list-style-type: none;
}
.nav > .nav-list > li a {
  text-decoration: none;
  color: #efefef;
  cursor: pointer;
}
.nav > #nav-check {
    display: none;
}
@media (max-width: 480px) {
  .nav {
    padding: 1rem;
  }
  .nav > .nav-btn {
    display: inline-block;
    position: absolute;
    right: 0;
    top: 0;
    padding-top: 0.2rem;
  }
  .nav > .nav-btn > label {
    display: inline-block;
    width: 50px;
    height: 50px;
    padding: 13px;
  }
  .nav > .nav-btn > label > span {
    display: block;
    width: 25px;
    height: 10px;
    border-top: 2px solid #eee;
  }
  .nav > .nav-list {
    position: absolute;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
    background-color:rgb(77, 167, 77);
    height: 0;
    transition: all 0.3s ease-in;
    top: 40px;
    left: 0;
    overflow: hidden;
    z-index: 2;
  }
  .nav > .nav-list > li {
    width: 100%;
    margin-top: 1.5rem;
  }
  .nav > #nav-check:checked ~ .nav-list {
    height: calc(100vh - 50px);
  }
}