.product{
    width: 100%;
    display: flex;
    background-image: url('../Images/p3.png');
    background-size: cover; 
    justify-content: center;
    align-items: center;
    flex-direction: column;
 
    margin-top: 80px;
    margin-bottom: 80px;
    height: 550px;
    opacity: 0.5;
    position: relative; 
  }
  .product h4{
    /* font-weight: bold; */
    color: whitesmoke;
    position: relative;
    z-index: 5;
   
  }
  .product h1{
    font-weight: bold;
    color: whitesmoke;
    position: relative;
    z-index: 5;
   
  }
  .product::before {
    content: ""; /* Add a pseudo-element */
    position: absolute; /* Position the pseudo-element */
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4); /* Adjust the opacity of the background */
   
  }
.product-col{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  width: 50%;
  margin: 0 auto;
  margin-bottom: 50px;
}
.contactinfo{
  text-align:justify;
  width: 70%;
  height:max-content;
  background-color: rgb(245, 245, 233);
  margin:30px auto ;
}
@media (max-width: 700px) {
.product-card{
  .add-more-button {
    display: block;
    margin: 20px auto 0;
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .add-more-button:hover {
    background-color: #0056b3;
  }
  
  .show-more .col.more-grapes-item {
    order: -1; /* Move new items to the top */
  }
  
  .more-grapes-item {
    transition: all 0.3s ease;
  }
  .availability-row {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 10px;
    margin-top: 15px;
  }
  
  .month-status {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 0.85rem;
    padding: 5px;
    text-align: center;
  }
  
  .status {
    margin-top: 5px;
    padding: 2px 5px;
    border-radius: 3px;
    font-weight: bold;
  }
  
  .available {
    background-color: #d4edda;
    color: #155724;
  }
  
  .unavailable {
    background-color: #f8d7da;
    color: #721c24;
  }
  
}

}
@media only screen and (min-width: 700px) and (max-width: 1250px) {
  .product-col {
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* Two items per row */
    gap: 20px; /* Space between items */
  }


  /* /////////////////////// */
  .add-more-button {
    display: block;
    margin: 20px auto 0;
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .add-more-button:hover {
    background-color: #0056b3;
  }
  
  .show-more .col.more-grapes-item {
    order: -1; /* Move new items to the top */
  }
  
  .more-grapes-item {
    transition: all 0.3s ease;
  }
  .availability-row {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 10px;
    margin-top: 15px;
  }
  
  .month-status {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 0.85rem;
    padding: 5px;
    text-align: center;
  }
  
  .status {
    margin-top: 5px;
    padding: 2px 5px;
    border-radius: 3px;
    font-weight: bold;
  }
  
  .available {
    background-color: #d4edda;
    color: #155724;
  }
  
  .unavailable {
    background-color: #f8d7da;
    color: #721c24;
  }
  
}

.main-container {
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
}

.product {
  text-align: center;
  margin-bottom: 20px;
}

.product-card {
  margin-top: 20px;
}

.product-col {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.cardpro {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.col {
  flex: 1;
  min-width: 300px;
  max-width: 300px;
  margin: 0 auto;
}

.card {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  overflow: hidden;
}

.card img {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.card-body {
  padding: 15px;
  text-align: center;
}

.add-more-button {
  display: block;
  margin: 20px auto 0;
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.add-more-button:hover {
  background-color: #0056b3;
}

.show-more .col.more-grapes-item {
  order: -1; /* Move new items to the top */
}

.more-grapes-item {
  transition: all 0.3s ease;
}
.availability-row {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
  margin-top: 15px;
}

.month-status {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 0.85rem;
  padding: 5px;
  text-align: center;
}

.status {
  margin-top: 5px;
  padding: 2px 5px;
  border-radius: 3px;
  font-weight: bold;
}

.available {
  background-color: #d4edda;
  color: #155724;
}

.unavailable {
  background-color: #f8d7da;
  color: #721c24;
}

