.about {
    width: 100%;
    display: flex;
    background-image: url('../Images/p3.png');
    background-size: cover; 
    justify-content: center;
    align-items: center;
    flex-direction: column;
 
    margin-top: 80px;
    margin-bottom: 80px;
    height: 550px;
    opacity: 0.5;
    position: relative; 
  }
  .about h1{
    font-weight: bold;
    color: whitesmoke;
    position: relative;
    z-index: 5;
   
  }
  .about h4{
    /* font-weight: bold; */
    color: whitesmoke;
    position: relative;
    z-index: 5;
   
  }
  .about::before {
    content: ""; /* Add a pseudo-element */
    position: absolute; /* Position the pseudo-element */
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4); /* Adjust the opacity of the background */
   
  }
  .about-inner {
    margin-top: 50px;
    text-align: justify;
    margin-bottom: 50px;
    padding: 50px 0; /* Adjust padding as needed */
}

.about-inner-img img {
    max-width: 100%; /* Ensure image is responsive */
}

.about-inner-content {
    padding-left: 30px; /* Adjust spacing between image and content */
}

.about-inner-content h3 {
    font-size: 24px; 
    font-weight: bold;
    color: #333; 
}

.about-inner-content p {
    font-size: 16px; 
    line-height: 1.6; 
    color: #666; 
}

.about-funfact {
    display: flex;
    margin-top: 20px; 
}
.section-padding{
    margin-bottom: 80px;
}
.single-about-funfact {
    margin-top: 20px;
    flex: 1; 
    text-align: center;
     
}
.key-benifits-item {
    display: flex;
    align-items: flex-end; /* Align flex items to the bottom of the container */
}
.single-about-funfact h4 {
    font-size: 24px; 
    font-weight: bold; 
    color: #333;
}

.single-about-funfact p {
    font-size: 16px; 
    margin-top: 5px; 
    color: #666; 
}
.key-benifits-content h3 {
    font-size: 24px;
    font-weight: bold;
    color: #333;
}

.key-benifits-content p {
    font-size: 16px;
    line-height: 1.6;
    color: #666;
}

.key-benifits-img img {
    max-width: 100%;
}


@media (max-width: 480px) {
    .about-inner-content{
       margin-top: 20px;
       margin-right: 25px;
    }
    .main-single-service-v2{
        margin-left: 25px;
        width: 90%;
    }
}
@media(max-width:700px){
    .about-inner-content{
        width: 100%;
    }
}

/* ///////////////////////////////////////////////// */


html, body {
    margin: 0;
    padding: 0;
    font-family: arial;
    box-sizing: border-box;
}

.main-section {
  height: 100%;
  display: flex;
  flex-basis: column nowrap;
  align-items: center;
  justify-content: center;
  background-color: white;
}

.card-span {
  display: inline-block;
  width: 3.125em;
  height: 0.15em;
  background-color: #66aa77;
  margin-bottom: 1.875em;
}

.card-container {
  background-color: white;
  color: black;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 1.875em;
  box-shadow: 0 0.5em 1em rgba(0, 0, 0, 0.45);
}



.cardImage {
  width: 100%;
}

.article-title {
  font-weight: 600;
  font-size: 2em;
  margin: 0 0 0.9em;
}

.article-descrip {
  font-weight: 300;
  line-height: 1.25em;
  margin: 0 0 1.9em 0;
}

.read-more-button {
  text-decoration: none;
  text-transform: uppercase;
  color: #66aa77;
  font-weight: bold;
  font-size: 0.8em;
  position: relative;
  display: inline-block;
  z-index: 1;
  padding: 0.3125em;
  left: -0.3125em;
}

.read-more-button:hover {
  color: #66aa77;
}

.read-more-button::before {
  content: '';
  z-index: -1;
  position: absolute;
  top: calc(50%);
  left: 0;
  width: 100%;
  height: 40%;
  pointer-events: none;
  background:   #6FDEC9;
  transform: scale3d(0, 1, 1);
  transform-origin: 0% 50%;
  transition: transform 0.33s;
}

.read-more-button:hover::before {
  transform: scale3d(1, 1, 1);
  transform-origin: 0% 50%;
}

.cardImage {
  height: 300px;
  width: 100%;
  background-position: center;
  background-size: cover;
}

.card-text-container {
  margin: 60px 45px 55px;
}


@media screen and (min-width: 768px) {
  .card-container {
    flex-direction: row;
    width: 100%;
  }
  .cardImage {
    height: 26.25em;
    min-width: 22.5em;
    background-size: cover;
  }
 .card-text-container {
    margin: 0 3.75em;
    max-width: 41.25em;
  }
}

@media screen and (min-width: 1024px) {
  .card-container {
    max-width: 75em;
  }
}