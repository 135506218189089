.App {
  text-align: center;
  
}
body {
  overflow-x: hidden;
}
html, body {
  width: 100%;
  margin: 0;
  padding: 0;
  overflow-x: hidden; /* Hide horizontal overflow */
}

/* Set the width of the main container to 100% */
.container {
  width: 100%;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.main-container {
  width: 100%;
  max-width: 1400px; /* Default for larger screens */
  margin: 0 auto;
  padding: 0 15px;
}






@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.main-container {
  width: 100%;
  /* width: calc(100% - 30px); */
  max-width: 1400px; /* Adjust this value as needed */
  margin: 0 auto;
  padding: 0 15px; /* Optional: adjust for desired padding */
}

.header, .footer, .content {
  width: 100%;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
