.contact{
    width: 100%;
    display: flex;
    background-image: url('../Images/p3.png');
    background-size: cover; 
    justify-content: center;
    align-items: center;
    flex-direction: column;
 
    margin-top: 80px;
    margin-bottom: 80px;
    height: 550px;
    opacity: 0.5;
    position: relative; 
  }
  .contact h1{
    font-weight: bold;
    color: whitesmoke;
    position: relative;
    z-index: 5;
   
  }
  .contact h4{
    /* font-weight: bold; */
    color: whitesmoke;
    position: relative;
    z-index: 5;
   
  }
  .contact::before {
    content: ""; /* Add a pseudo-element */
    position: absolute; /* Position the pseudo-element */
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4); /* Adjust the opacity of the background */
   
  }
  /* 888888888888888888888888888 */
  .social-c{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
  }
  .row{
    color: black;
  }
  .xs-contact-infomation {
    padding: 50px 0;
    background-color: #f9f9f9;
}

.contact-info-group {
    text-align: center;
    margin-bottom: 30px;
}

.contact-info-group i {
    font-size: 24px;
    margin-bottom: 15px;
}

.contact-info-group h4 {
    font-size: 18px;
    margin-bottom: 15px;
}

.contact-info-group span {
    display: block;
    font-size: 16px;
    line-height: 24px;
}

.contact-info-group a {
    display: block;
    font-size: 16px;
    line-height: 24px;
    color: #333;
    text-decoration: none;
}

.contact-info-group a:hover {
    text-decoration: underline;
}

.text-color {
    color: #333;
}
.contact-info-group{
    height: 200px;
    background-color: rgb(245, 245, 233);
    width: 300px;
}
/* 888888888888888888888888888888 */
.form-card {
   
    width: 400px;
    margin: 80px auto;
    padding: 50px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.form-card form {
    display: flex;
    flex-direction: column;
}

.form-card label {
    margin-bottom: 8px;
}

.form-card input,
.form-card textarea {
    padding: 8px;
    margin-bottom: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.form-card input[type="submit"] {
    background-color: #28a745;
    color: #fff;
    border: none;
    border-radius: 4px;
    padding: 10px 20px;
    cursor: pointer;
}

.form-card input[type="submit"]:hover {
    background-color: #1d4b28;
}
@media (max-width: 750px) {
   .social-c{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 50%;
   }
}
.contactinfo{
    text-align:justify;
    width: 70%;
    height:max-content;
    background-color: rgb(245, 245, 233);
    margin:30px auto ;
}